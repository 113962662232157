<template>
  <div>
    <vue-type
      title="Canal de venta"
      permission-type="typeclient"
      url-get-register="/v1/type-client/get/"
      url-delete-register="/v1/type-client/get/delete"
      url-active-register="/v1/type-client/update/active/"
      url-delete-register-one="/v1/type-client/delete/"
      url-delete-all="/v1/type-client/delete/"
      url-delete-register-back="/v1/type-client/delete/restore/"
      url-delete-all-back="/v1/type-client/delete/restoreall"
      url-update-one="/v1/type-client/update/"
      url-create-one="/v1/type-client/create"
    />
  </div>
</template>
<script>
import TypeOpcion from '@/components/optionsCrud/typeIndex.vue'

export default {
  components: {
    'vue-type': TypeOpcion,
  },
}
</script>
